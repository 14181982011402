import React, {useState, useEffect, useCallback} from 'react';
import {Button} from "devextreme-react/button";



export default function Help() {
    return (
        <React.Fragment>
            <h2 className={'content-block'}>Помощь(сраница в процессе разработки)</h2>
            <a href={'https://t.me/+4zdUFO4mbDAxNjli'} target={'_blank'}><center><Button

                text="Задать вопрос"
                type="success"
                stylingMode="outlined"
                //onClick={() => PoiskPoid()}
                icon="help"


            />
                </center></a>
                    <div className={'content-block'}>

                        <div className={'dx-card responsive-paddings'} style={{fontSize: '1em'}}>
                            <div className={'title-table'}><b>ФАЙЛЫ</b></div>


                            <ul>
                                <li><a href={require('./file/ГГГГ-ММ-ДД-sm.xlsx')} download="ГГГГ-ММ-ДД-sm"
                                       target="_blank"
                                       rel="noreferrer">Шаблон ежедневного меню</a></li>
                                <li> Шаблон ежедневного меню (раcширенный вариант)</li>
                                <li> Шаблон файла для «Рейтинг общественной оценки» (findex.xlsx)</li>
                                <li> Шаблоны «Типовое меню» 2×5</li>
                                <li> Шаблоны «Типовое меню» 4×5</li>
                                <li> Шаблоны «Типовое меню» 2×7</li>
                                <li> Шаблоны «Типовое меню» 2×6</li>
                                <li> Шаблоны «Типовое меню» 3×5</li>
                                <li> Шаблон «Календарь питания» (2024, 2×5, с примером заполнения)</li>
                            </ul>
                        </div>
                    </div>
                    <div className={'content-block'}>

                        <div className={'dx-card responsive-paddings'} style={{fontSize: '1em'}}>


                            <div className={'title-table'}><b>ИНСТРУКЦИИ</b></div>
                            <ul>
                                <li>Общая инструкция по мониторингу</li>
                                <li>Инструкция по Типовому меню и Календарю питания</li>

                            </ul>
                        </div>
                    </div>
                    <div className={'content-block'}>

                        <div className={'dx-card responsive-paddings'} style={{fontSize: '1em'}}>
                            <div className={'title-table'}><b>НОРМАТИВНАЯ БАЗА</b></div>
                            <ul>
                                <li>Письмо Министерства просвещения о размещении меню ГД-1158/01 от 17.05.2021</li>



                            </ul>
                        </div>
                    </div>
            <div className={'content-block'}>

                        <div className={'dx-card responsive-paddings'} style={{fontSize: '1em'}}>
                            <div className={'title-table'}><b>ВЕБИНАРЫ</b></div>
                            <ul>
                                <li>Запись вебинаров на тему «Школьное питание» на канале
                                    https://rutube.ru/channel/35063550/
                                </li>


                            </ul>
                        </div>
                    </div>


        </React.Fragment>
    )
}