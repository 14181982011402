import React, {useCallback, useEffect, useState} from 'react';
import PivotGrid, {
    FieldChooser,
    Export,
    Scrolling,
    FieldPanel,
} from 'devextreme-react/pivot-grid';
import {loadMessages, locale} from "devextreme/localization";
import ruMessages from 'devextreme/localization/messages/ru.json';
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
import {exportDataGrid, exportPivotGrid} from 'devextreme/excel_exporter';
import Switch, { SwitchTypes } from 'devextreme-react/switch';
import {Lookup} from "devextreme-react/lookup";
import {Button} from "devextreme-react/button";
import {FilterRow, HeaderFilter, LoadPanel, Paging, SearchPanel} from "devextreme-react/data-grid";
import DataGrid, {
     DataGridTypes, GroupPanel, Column,Summary,GroupItem,TotalItem,
} from 'devextreme-react/data-grid';

export default function Upload() {
//Общие настройки

    //Руссификация сообщений
    locale(sessionStorage.getItem('locale') || 'ru');
    loadMessages(ruMessages);

//Меню выбора отчета региона и т.п.
// переменные selectrotchet - отчет selectregion - регион selectgod - год selectmes  - месяц

    // Выбранный отчёт
    const [selectrotchet, setselectrotchet] = useState(1);
    const ChangeOtchet = useCallback((e: SelectBoxTypes.ValueChangedEvent) => {
        setreedit(true);
        setselectrotchet(e.value);
        setdatamenuload('[]');
    }, []);
    // Выбранный регион
    const [selectregion, setselectregion] = useState();
    const ChangeRegion = useCallback((e: SelectBoxTypes.ValueChangedEvent) => {
        setreedit(true);
        setselectregion(e.value);
        setdatamenuload('[]');
    }, []);
    // Выбранный год
    const [selectgod, setselectgod] = useState(2024);
    const ChangeGod = useCallback((e: SelectBoxTypes.ValueChangedEvent) => {
        setreedit(true);
        setselectgod(e.value);
        setdatamenuload('[]');
    }, []);
    // Выбранный месяц
    const [selectmes, setselectmes] = useState('11');
    const ChangeMes = useCallback((e: SelectBoxTypes.ValueChangedEvent) => {
        setreedit(true);
        setselectmes(e.value);
        setdatamenuload('[]');
    }, []);
    const [postroy, setpostroy] = useState(false);
    const [otchetstatus, setotchetstatus] = useState('Построить отчет');
    const [reedit, setreedit] = useState(true);




    //Масссивы данных для списков
    const otchetvid = [
        {"label": "Публикация фактического меню","value": 0},
        {"label": "Соответствие типового и фактического меню","value": 1},
        {"label": "Ошибки в фактическом меню","value": 2},
        {"label": "Одинаковые ссылки у пищеблоков в регионе","value": 3},
        {"label": "Пустые фактические меню (или с нарушенным шаблоном)","value": 4},
        {"label": "Даты типового меню, календаря питания, род.контроля)","value": 5},
    ];

    const region = [
        {
            "label": "Алтайский край",
            "value": 34688
        },
        {
            "label": "Амурская область",
            "value": 277
        },
        {
            "label": "Архангельская область",
            "value": 49102
        },
        {
            "label": "Астраханская область",
            "value": 27420
        },
        {
            "label": "Белгородская область",
            "value": 4105
        },
        {
            "label": "Брянская область",
            "value": 42
        },
        {
            "label": "Владимирская область",
            "value": 4106
        },
        {
            "label": "Волгоградская область",
            "value": 27667
        },
        {
            "label": "Вологодская область",
            "value": 170
        },
        {
            "label": "Воронежская область",
            "value": 27
        },
        {
            "label": "ДНР",
            "value": 124113
        },
        {
            "label": "Еврейская автономная область",
            "value": 4109
        },
        {
            "label": "Забайкальский край",
            "value": 147
        },
        {
            "label": "Запорожская область",
            "value": 124287
        },
        {
            "label": "Ивановская область",
            "value": 26331
        },
        {
            "label": "Иркутская область",
            "value": 207
        },
        {
            "label": "Кабардино-Балкарская Республика",
            "value": 196
        },
        {
            "label": "Калининградская область",
            "value": 27077
        },
        {
            "label": "Калужская область",
            "value": 32534
        },
        {
            "label": "Камчатский край",
            "value": 4112
        },
        {
            "label": "Карачаево-Черкесская Республика",
            "value": 304
        },
        {
            "label": "Кемеровская область",
            "value": 4113
        },
        {
            "label": "Кировская область",
            "value": 81
        },
        {
            "label": "Костромская область",
            "value": 45
        },
        {
            "label": "Краснодарский край",
            "value": 296
        },
        {
            "label": "Красноярский край",
            "value": 121595
        },
        {
            "label": "Курганская область",
            "value": 4115
        },
        {
            "label": "Курская область",
            "value": 57095
        },
        {
            "label": "Ленинградская область",
            "value": 25
        },
        {
            "label": "Липецкая область",
            "value": 178
        },
        {
            "label": "Магаданская область",
            "value": 4116
        },
        {
            "label": "Москва",
            "value": 4107
        },
        {
            "label": "Московская область",
            "value": 39184
        },
        {
            "label": "Мурманская область",
            "value": 31746
        },
        {
            "label": "Ненецкий автономный округ",
            "value": 205
        },
        {
            "label": "Нижегородская область",
            "value": 280
        },
        {
            "label": "Новгородская область",
            "value": 287
        },
        {
            "label": "Новосибирская область",
            "value": 33595
        },
        {
            "label": "Омская область",
            "value": 127
        },
        {
            "label": "Оренбургская область",
            "value": 2464
        },
        {
            "label": "Орловская область",
            "value": 181
        },
        {
            "label": "Пензенская область",
            "value": 4118
        },
        {
            "label": "Пермский край",
            "value": 124
        },
        {
            "label": "Приморский край",
            "value": 254
        },
        {
            "label": "Псковская область",
            "value": 35731
        },
        {
            "label": "Республика Адыгея",
            "value": 4120
        },
        {
            "label": "Республика Алтай",
            "value": 4121
        },
        {
            "label": "Республика Башкортостан",
            "value": 49591
        },
        {
            "label": "Республика Бурятия",
            "value": 113
        },
        {
            "label": "Республика Дагестан",
            "value": 214
        },
        {
            "label": "Республика Ингушетия",
            "value": 31140
        },
        {
            "label": "Республика Калмыкия",
            "value": 108
        },
        {
            "label": "Республика Карелия",
            "value": 85
        },
        {
            "label": "Республика Коми",
            "value": 4122
        },
        {
            "label": "Республика Крым",
            "value": 4123
        },
        {
            "label": "Республика Марий Эл",
            "value": 91
        },
        {
            "label": "Республика Мордовия",
            "value": 4124
        },
        {
            "label": "Республика Саха",
            "value": 266
        },
        {
            "label": "Республика Северная Осетия - Алания",
            "value": 4125
        },
        {
            "label": "Республика Татарстан",
            "value": 23
        },
        {
            "label": "Республика Тыва",
            "value": 4126
        },
        {
            "label": "Республика Хакасия",
            "value": 4127
        },
        {
            "label": "Ростовская область",
            "value": 24816
        },
        {
            "label": "Рязанская область",
            "value": 29339
        },
        {
            "label": "Самарская область",
            "value": 1312
        },
        {
            "label": "Санкт-Петербург",
            "value": 36
        },
        {
            "label": "Саратовская область",
            "value": 4129
        },
        {
            "label": "Сахалинская область",
            "value": 29195
        },
        {
            "label": "Свердловская область",
            "value": 28356
        },
        {
            "label": "Севастополь",
            "value": 4108
        },
        {
            "label": "Смоленская область",
            "value": 30437
        },
        {
            "label": "Ставропольский край",
            "value": 1348
        },
        {
            "label": "Тамбовская область",
            "value": 31420
        },
        {
            "label": "Тверская область",
            "value": 29989
        },
        {
            "label": "Томская область",
            "value": 31930
        },
        {
            "label": "Тульская область",
            "value": 452
        },
        {
            "label": "Тюменская область",
            "value": 29622
        },
        {
            "label": "Удмуртская республика",
            "value": 47503
        },
        {
            "label": "Ульяновская область",
            "value": 93
        },
        {
            "label": "Хабаровский край",
            "value": 164
        },
        {
            "label": "Ханты-Мансийский автономный округ - Югра",
            "value": 3348
        },
        {
            "label": "Челябинская область",
            "value": 48307
        },
        {
            "label": "Чеченская республика",
            "value": 4133
        },
        {
            "label": "Чувашская Республика",
            "value": 1532
        },
        {
            "label": "Чукотский автономный округ",
            "value": 270
        },
        {
            "label": "Ямало-Ненецкий автономный округ",
            "value": 36842
        },
        {
            "label": "Ярославская область",
            "value": 35972
        }
    ];
    const god = [
        {"label": "2021","value": 2021},
        {"label": "2022","value": 2022},
        {"label": "2023","value": 2023},
        {"label": "2024","value": 2024},
        {"label": "2025","value": 2025},
    ];
    const mesac = [
        {"label": "январь","value": "01"},
        {"label": "февраль","value": "02"},
        {"label": "март","value": "03"},
        {"label": "апрель","value": "04"},
        {"label": "май","value": "05"},
        {"label": "июнь","value": "06"},
        {"label": "июль","value": "07"},
        {"label": "август","value": "08"},
        {"label": "сентябрь","value": "09"},
        {"label": "октябрь","value": "10"},
        {"label": "ноябрь","value": "11"},
        {"label": "декабрь","value": "12"},
    ];



//Отчет

    const [datamenuload, setdatamenuload] = useState('[]');
    const [data1, setdata1] = useState();


    const [exp_ray, setexp_ray] = useState(false);

    const getdataMenu = async (link,id,m,g) => {
        const response = await fetch(
            link+id+"&m="+m+"&g="+g
        ).then((response) => response.json())
            .catch(err => alert('База данных недоступна. Попробуйте позже'))

        setdatamenuload(response);
        setpostroy(false);
        setotchetstatus('Построить отчет');
    };

    const valueChanged =useCallback(({ value }) => {
        setexp_ray(exp_ray =>!exp_ray);
    }, []);






    const dataSource = new PivotGridDataSource({
        fields: [{
            caption: 'Район',
            width: 120,
            dataField: 'Район',
            area: 'row',
            expanded: exp_ray,
        }, {
            caption: 'Наименование',
            dataField: 'Наименование',
            width: 150,
            area: 'row',


        }, {
            dataField: 'Дата',
            dataType: 'Дата',
            area: 'column',
        }, {
            caption: 'Код',
            dataField: 'Код',
            area: 'data',
        }],
        store: datamenuload,
    });

    const onExporting = (e: PivotGridTypes.ExportingEvent) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Экспорт');

        exportPivotGrid({
            component: e.component,
            worksheet,
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Экспорт.xlsx');
            });
        });
    };

    const onExportingg = (e: DataGridTypes.ExportingEvent) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Выгрузка');

        exportDataGrid({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Экспорт.xlsx');
            });
        });
    };



            const Otcetone = useCallback(() => {
if(reedit){return(<div></div>);}
if(selectrotchet==0){
                return(
                    <div>
                        <div>Развернуть значения <Switch value={exp_ray} onValueChanged={valueChanged}/></div>
                        <PivotGrid
                            dataSource={dataSource}
                            allowSortingBySummary={true}
                            allowFiltering={true}
                            showBorders={true}
                            showColumnTotals={true}
                            showColumnGrandTotals={true}
                            showRowTotals={true}
                            showRowGrandTotals={true}
                            rowHeaderLayout={'tree'}
                            onExporting={onExporting}

                        >
                            <FilterRow visible={true}/>
                            <Export enabled={true}/>
                            <FieldChooser enabled={true} height={400}/>
                            <FieldPanel
                                showColumnFields={true}
                                showDataFields={false}
                                showFilterFields={false}
                                showRowFields={true}
                                allowFieldDragging={true}
                                visible={true}
                            />

                        </PivotGrid>

                        SHIFT+колёсико мыши - горизонтальная прокрутка
                    </div>
                )}
if(selectrotchet>=1){
                    return(
                        <div>
                            <DataGrid
                                      dataSource={data1}
                                      showBorders={true}
                                      onExporting={onExportingg}
                                      wordWrapEnabled={true}>
                                <FilterRow visible={true}/>
                                <SearchPanel visible={true}/>
                                <HeaderFilter visible={true}/>
                                <Paging pageSize={100}/>
                                <Export enabled={true}/>


                                />
                                <LoadPanel
                                    height={100}
                                    width={200}
                                    indicatorSrc={require('./load.gif')}

                                />




                            </DataGrid>
                        </div>
                    )}

            }, [dataSource]);


    const Postroitotcet = () => {

        if(selectrotchet==0){
            setreedit(false);
            getdataMenu('https://api.cemon.ru/otcheti/menupub/?id=',selectregion,selectmes,selectgod);
            setpostroy(true);
            setotchetstatus('Загрузка данных...');
        }
        if(selectrotchet==1) {
            setreedit(false);
            setdata1('https://api.cemon.ru/otcheti/sootvmenu/?id='+selectregion+'&m='+selectmes+'&g='+selectgod);

        }
        if(selectrotchet==2) {
            setreedit(false);
            setdata1('https://api.cemon.ru/otcheti/menuoshib/?id='+selectregion+'&m='+selectmes+'&g='+selectgod);

        }
        if(selectrotchet==3) {
            setreedit(false);
            setdata1('https://api.cemon.ru/otcheti/dubllink/?id='+selectregion+'&m='+selectmes+'&g='+selectgod);

        }
        if(selectrotchet==4) {
            setreedit(false);
            setdata1('https://api.cemon.ru/otcheti/blankmenu/?id='+selectregion+'&m='+selectmes+'&g='+selectgod);

        }
        if(selectrotchet==5) {
            setreedit(false);
            setdata1('https://api.cemon.ru/otcheti/dati_tm_kp_rk/?id='+selectregion+'&m='+selectmes+'&g='+selectgod);

        }


    }


    return (
        <React.Fragment>
            <h2 className={'content-block'}>Отчёт</h2>


            <div className={'content-block'}>
                <div className={'dx-card responsive-paddings'} style={{fontSize: '14px'}}>
                    <Lookup
                        label="Отчет"
                        labelMode='static'
                        stylingMode='outlined'
                        dataSource={otchetvid}
                        displayExpr="label"
                        valueExpr="value"
                        searchEnabled={false}
                        placeholder="Выбор отчета"
                        value={selectrotchet}
                        onValueChanged={ChangeOtchet}
                    />
                    <Lookup
                        label="Регион"
                        labelMode='static'
                        stylingMode='outlined'
                        dataSource={region}
                        displayExpr="label"
                        valueExpr="value"
                        searchEnabled={true}
                        placeholder="Выбор региона"
                        onValueChanged={ChangeRegion}
                        value={selectregion}
                        //selectedItem={selectregion}
                        //onDataErrorOccurred={lanerror}
                />
                <Lookup
                    label="Год"
                    labelMode='static'
                    stylingMode='outlined'
                    dataSource={god}
                    displayExpr="label"
                    valueExpr="value"
                    searchEnabled={false}
                    placeholder="Выбор отчетного года"
                    onValueChanged={ChangeGod}
                    value={selectgod}
                    //selectedItem={selectregion}
                    //onDataErrorOccurred={lanerror}
                />
                <Lookup
                    label="Месяц"
                    labelMode='static'
                    stylingMode='outlined'
                    dataSource={mesac}
                    displayExpr="label"
                    valueExpr="value"
                    searchEnabled={false}
                    placeholder="Выбор отчетного месяца"
                    value={selectmes}
                    onValueChanged={ChangeMes}
                    //selectedItem={selectregion}
                    //onDataErrorOccurred={lanerror}
                />
                <Button
                    width={180}
                    text={otchetstatus}
                    //type="success"
                    stylingMode="contained"
                    onClick={() => Postroitotcet()}
                    icon="search"
                    disabled={postroy}
                />


            </div>

                <div className={'dx-card responsive-paddings'} style={{fontSize: '14px'}}>

                    <Otcetone />



                </div>
            </div>
    </React.Fragment>
)}
