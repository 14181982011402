import React, {useState, useEffect, useCallback} from 'react';
import './home.scss';
import Select from "react-select";
import DataGrid, {
    Paging, SearchPanel, HeaderFilter, FilterRow, DataGridTypes, Export, LoadPanel,GroupPanel, Column,Summary,GroupItem,TotalItem,Scrolling
} from 'devextreme-react/data-grid';
import ruMessages from 'devextreme/localization/messages/ru.json';
import { locale, loadMessages, date} from 'devextreme/localization';
import {Workbook} from "exceljs";
import {exportDataGrid} from "devextreme/excel_exporter";
import {saveAs} from "file-saver-es";
import Chart, {ArgumentAxis,Legend,Series,ValueAxis,Label,Tick,Aggregation, Animation} from 'devextreme-react/chart';
import Moment from 'moment';
import {SelectBox} from "devextreme-react/select-box";
import {Lookup} from "devextreme-react/lookup";
import copy from 'copy-to-clipboard';

export default function Home() {
    //Руссификация сообщений
    locale(sessionStorage.getItem('locale') || 'ru');
    loadMessages(ruMessages);
    const [data, setdata] = useState('https://api.cemon.ru/opros/statregion/all.json');
    console.clear();

    const onExporting = (e: DataGridTypes.ExportingEvent) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Выгрузка');

        exportDataGrid({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Экспорт.xlsx');
            });
        });
    };

    const getgod = [
        {"label": "Все регионы","value": 0},
        {"label": "Алтайский край","value": 1},
        {"label": "Амурская область","value": 2},
        {"label": "Архангельская область", "value": 3},
        {"label": "Астраханская область", "value": 4},
        {            "label": "Белгородская область",            "value": 5        },
        {            "label": "Брянская область",            "value": 6        },
        {            "label": "Владимирская область",            "value": 7        },
        {            "label": "Волгоградская область",            "value": 8        },
        {            "label": "Вологодская область",            "value": 9        },
        {            "label": "Воронежская область",            "value": 10        },
        {            "label": "Еврейская автономная область",            "value": 12        },
        {            "label": "Забайкальский край",            "value": 13        },
        {            "label": "Ивановская область",            "value": 15        },
        {            "label": "Иркутская область",            "value": 16        },
        {            "label": "Кабардино-Балкарская Республика",            "value": 17        },
        {            "label": "Калининградская область",            "value": 18        },
        {            "label": "Калужская область",            "value": 19        },
        {            "label": "Камчатский край",            "value": 20        },
        {            "label": "Карачаево-Черкесская Республика",            "value": 21        },
        {            "label": "Кемеровская область",            "value": 22        },
        {            "label": "Кировская область",            "value": 23        },
        {            "label": "Костромская область",            "value": 24        },
        {            "label": "Краснодарский край",            "value": 25        },
        {            "label": "Красноярский край",            "value": 26        },
        {            "label": "Курганская область",            "value": 27        },
        {            "label": "Курская область",            "value": 28        },
        {            "label": "Ленинградская область",            "value": 29        },
        {            "label": "Липецкая область",            "value": 30        },
        {
            "label": "Магаданская область",
            "value": 31
        },
        {
            "label": "Москва",
            "value": 32
        },
        {
            "label": "Московская область",
            "value": 33
        },
        {
            "label": "Мурманская область",
            "value": 34
        },
        {
            "label": "Ненецкий автономный округ",
            "value": 35
        },
        {
            "label": "Нижегородская область",
            "value": 36
        },
        {
            "label": "Новгородская область",
            "value": 37
        },
        {
            "label": "Новосибирская область",
            "value": 38
        },
        {
            "label": "Омская область",
            "value": 39
        },
        {
            "label": "Оренбургская область",
            "value": 40
        },
        {
            "label": "Орловская область",
            "value": 41
        },
        {
            "label": "Пензенская область",
            "value": 42
        },
        {
            "label": "Пермский край",
            "value": 43
        },
        {
            "label": "Приморский край",
            "value": 44
        },
        {
            "label": "Псковская область",
            "value": 45
        },
        {
            "label": "Республика Адыгея",
            "value": 46
        },
        {
            "label": "Республика Алтай",
            "value": 47
        },
        {
            "label": "Республика Башкортостан",
            "value": 48
        },
        {
            "label": "Республика Бурятия",
            "value": 49
        },
        {
            "label": "Республика Дагестан",
            "value": 50
        },
        {
            "label": "Республика Ингушетия",
            "value": 51
        },
        {
            "label": "Республика Калмыкия",
            "value": 52
        },
        {
            "label": "Республика Карелия",
            "value": 53
        },
        {
            "label": "Республика Коми",
            "value": 54
        },
        {
            "label": "Республика Крым",
            "value": 55
        },
        {
            "label": "Республика Марий Эл",
            "value": 56
        },
        {
            "label": "Республика Мордовия",
            "value": 57
        },
        {
            "label": "Республика Саха",
            "value": 58
        },
        {
            "label": "Республика Северная Осетия - Алания",
            "value": 59
        },
        {
            "label": "Республика Татарстан",
            "value": 60
        },
        {
            "label": "Республика Тыва",
            "value": 61
        },
        {
            "label": "Республика Хакасия",
            "value": 62
        },
        {
            "label": "Ростовская область",
            "value": 63
        },
        {
            "label": "Рязанская область",
            "value": 64
        },
        {
            "label": "Самарская область",
            "value": 65
        },
        {
            "label": "Санкт-Петербург",
            "value": 66
        },
        {
            "label": "Саратовская область",
            "value": 67
        },
        {
            "label": "Сахалинская область",
            "value": 68
        },
        {
            "label": "Свердловская область",
            "value": 69
        },
        {
            "label": "Севастополь",
            "value": 70
        },
        {
            "label": "Смоленская область",
            "value": 71
        },
        {
            "label": "Ставропольский край",
            "value": 72
        },
        {
            "label": "Тамбовская область",
            "value": 73
        },
        {
            "label": "Тверская область",
            "value": 74
        },
        {
            "label": "Томская область",
            "value": 75
        },
        {
            "label": "Тульская область",
            "value": 76
        },
        {
            "label": "Тюменская область",
            "value": 77
        },
        {
            "label": "Удмуртская Республика",
            "value": 78
        },
        {
            "label": "Ульяновская область",
            "value": 79
        },
        {
            "label": "Хабаровский край",
            "value": 80
        },
        {
            "label": "Ханты-Мансийский автономный округ - Югра",
            "value": 81
        },
        {
            "label": "Челябинская область",
            "value": 82
        },
        {
            "label": "Чеченская Республика",
            "value": 83
        },
        {
            "label": "Чувашская Республика",
            "value": 84
        },
        {
            "label": "Чукотский автономный округ",
            "value": 85
        },
        {
            "label": "Ямало-Ненецкий автономный округ",
            "value": 86
        },
        {
            "label": "Ярославская область",
            "value": 87
        }
    ];


    const [grafikurl, setgrafikurl] = useState('https://api.cemon.ru/opros/statobsh/grafik.json');
    const [selectregionval, setselectregionval] = useState();


    const selectregion = useCallback(({ value }) => {
        if(value==0){
            setgrafikurl('https://api.cemon.ru/opros/statobsh/grafik.json');
        }
        else {
            setgrafikurl('https://api.cemon.ru/opros/statobsh/grafik_region.php?code='+value)
        };
        setselectregionval(value);
      }, []);

    const perehod = useCallback(({ value, columnIndex }) => {
        if (columnIndex==0){
            copy(value);

            }

    }, []);


  return (
      <React.Fragment>
          <h2 className={'content-block'}>Справочник пищеблоков</h2>
          <div className={'content-block'}>
              <div className={'dx-card responsive-paddings'} style={{fontSize: '14px'}}>
                  <DataGrid
                      dataSource={data}
                      showBorders={true}
                      width='100%'
                      allowColumnResizing={true}
                      style={{paddingTop: 0, paddingBottom: 0}}
                      columnAutoWidth={true}
                      columnHidingEnabled={false}
                      showColumnLines={true}
                      //dataRowRender={DataRow}
                      //hoverStateEnabled={true}
                      onExporting={onExporting}
                      wordWrapEnabled={true}
                      onCellDblClick={perehod}

                  >

                      <FilterRow visible={true}/>
                      <SearchPanel visible={true}/>
                      <HeaderFilter visible={true}/>
                      <Paging pageSize={10}/>
                      <Export enabled={true}/>
                      <LoadPanel
                          height={100}
                          width={200}
                          indicatorSrc={require('./load.gif')}

                      />
                      <Column dataField="Код" allowSearch={false} />
                      <Column dataField="Наименование" allowSearch={true} />
                      <Column dataField="Регион" />
                      <Column dataField="Район" />
                      <Column dataField="Родители" allowSearch={false} dataType="number" />
                      <Column dataField="Дети" allowSearch={false} dataType="number" />


                  </DataGrid>
              </div>
          </div>

          <h2 className={'content-block'}>Количество респондентов опроса 2024</h2>
          <div className={'content-block'}>
              <div className={'dx-card responsive-paddings'} style={{fontSize: '14px'}}>
                  <Lookup
                      label="Регион"
                      labelMode='static'
                      stylingMode='outlined'
                      dataSource={getgod}
                      displayExpr="label"
                      valueExpr="value"
                      searchEnabled={true}
                      placeholder="Выбор региона"
                      onValueChanged={selectregion}
                      value={selectregionval}
                      //selectedItem={selectregion}
                      //onDataErrorOccurred={lanerror}
                  />


                  <Chart dataSource={grafikurl} rotated={false} title={'Количество ответов по часам (МСК) за '+Moment().format('DD.MM.YYYY')} >

                      <Series valueField="КОЛИЧЕСТВО" argumentField="ВРЕМЯ" type="stackedspline" color="#115FA3" name="Дети">

                          <Label visible={true} backgroundColor="#19B1BD"/>
                      </Series>
                      <Animation
                          enabled={false}
                      />
                      <Legend position="outside" visible={false} horizontalAlignment="center" verticalAlignment="top"/>
                      <Export enabled={true} />

                  </Chart>




                  <Chart dataSource={'https://api.cemon.ru/opros/statregion/data.json'} rotated={false} title={'Количество респондентов по ФО'}>

                  <Series valueField="Дети" argumentField="ФО" type="bar" color="#115FA3" name="Дети">
                      <Aggregation enabled={true} />
                      <Label visible={true} backgroundColor="#19B1BD"/>
                  </Series>
                      <Series valueField="Родители" argumentField="ФО" type="bar" color="#0F6CFF" name="Родители">
                          <Aggregation enabled={true} />
                          <Label visible={true} backgroundColor="#19B1BD"/>
                      </Series>
                  <ArgumentAxis>

                  </ArgumentAxis>

                  <Legend position="outside" visible={true} horizontalAlignment="center" verticalAlignment="top"/>

                  <Export enabled={true} />

              </Chart>




                  <DataGrid id="gridContainer"
                            dataSource={'https://api.cemon.ru/opros/statregion/data.json'}
                            showBorders={true}
                            onExporting={onExporting}
                            wordWrapEnabled={true}>
                      <FilterRow visible={true}/>
                      <SearchPanel visible={true}/>
                      <HeaderFilter visible={true}/>
                      <Paging pageSize={100}/>
                      <Export enabled={true}/>
                      <LoadPanel
                          height={100}
                          width={200}
                          indicatorSrc={require('./load.gif')}

                      />
                      <Column
                          dataField="ФО"
                          groupIndex={0}
                      />
                      <Column
                          dataField="Регион"

                      />
                      <Column
                          dataField="Дети"
                          allowSearch={false}
                          dataType="number"
                      />
                      <Column
                          dataField="% от общего кол-ва учащихся"
                          allowSearch={false}
                          caption="% от общего кол-ва учащихся 1-4 классов в регионе"
                      />
                      <Column
                          dataField="Родители"
                          allowSearch={false}
                          dataType="number"

                      />
                      <Summary>

                          <GroupItem
                              column="Дети"
                              summaryType="sum"
                              displayFormat="{0} детей"
                          />
                          <GroupItem
                              column="Родители"
                              summaryType="sum"
                              displayFormat="{0} родителей"
                          />
                          <TotalItem
                              column="Дети"
                              summaryType="sum" />
                          <TotalItem
                              column="Родители"
                              summaryType="sum" />
                      </Summary>
                  </DataGrid>
              </div>
          </div>

          <h2 className={'content-block'}>О мониторинге</h2>
          <div className={'content-block'}>
              <div className={'dx-card responsive-paddings'} style={{fontSize: '14px'}}>

                  Автоматизированный мониторинг организации горячего питания обучающихся включает:
                  <ul>
                      <li>средства наблюдения за меню фактического питания, опубликованных на официальных сайтах
                          образовательных организаций;
                      </li>
                      <li>анализ меню с выявлением отклонений данных о потреблении (выход блюд, калорийность и
                          основные
                          пищевые вещества по приемам пищи) от нормативов потребления;
                      </li>
                      <li>мониторинг стоимости комплексов блюд по приемам пищи;</li>
                      <li>состава меню фактического питания;</li>
                      <li>исполнительской дисциплиной по публикации меню на официальных сайтах.</li>
                  </ul>
                  Вышеперечисленные
                  параметры контролируются с использованием централизованной базы данных, аналитических
                  отчетов для специалистов ФЦМПО и региональных органов управления образованием, а также
                  публичного интерфейса анализа меню на специализированном интернет-сайте
                  https://мониторингпитание.рф. В развитие находящейся в эксплуатации системы мониторинга по
                  вышеперечисленным параметрам в систему внесены:
                  <ul>
                      <li>средства для построения рейтинга</li>
                      <li>общественного контроля;</li>
                      <li>визуализации результатов массовых социологических опросов по питанию;</li>
                      <li>средства взаимодействия со сторонними информационными ресурсами в части справочника
                          пищеблоков
                          и
                          школ
                      </li>
                      <li>сформулированы общие требования к проектируемым средствам
                          визуализации и специализированным рабочим местам специалистов региональных управлений
                          образования и экспертов ФЦМПО.
                      </li>
                  </ul>
                  Примечание: иные вопросы, относящиеся к управлению системой школьного питания (т. к.
                  мониторинг формы организации питания в школе и соответствующих систем ценообразования;
                  численность питающихся; фактический товарооборот продуктов и его сопоставление со
                  среднесуточными нормативами по продуктовым группам; контроль питания не только младшей
                  возрастной категории; обращения граждан и результаты их рассмотрения и др.) выходят за рамки
                  существующей системы мониторинга питания обучающихся и могут быть предметом технического
                  задания для последующих стадий (версий) системы мониторинга.
                  Задача мониторинга питания обучающийся начальных классов РФ.
                  В части мониторинга меню фактического питания, публикуемых на сайтах школ, система включает:
                  <ul>
                      <li> регулярное считывание файлов меню с установленным шаблоном имени файла
                          «гггг-мм-дд-sm.xlsx» в центральную базу данных;
                      </li>
                      <li> контроль считываемых файлов на соответствие формату бланка;</li>
                      <li> разбор скачанных файлов с помещение полученного в цифровом виде
                          документа в информационной базе (вида «Меню фактическое»)
                      </li>
                  </ul>
                  Данные работы выполняются в центральной информационной базе ФЦМПО (наплатформе
                  «1С:Предприятие»), размещенной на интернет-сайте <a
                  href='https://cemon.ru'>https://cemon.ru</a>, в
                  автоматическом режиме, один раз в сутки.
              </div>


          </div>
      </React.Fragment>
  )
}
